// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled. 
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER

global.$ = require("jquery");
window.$ = window.jQuery = $;   // https://stackoverflow.com/questions/29080148/expose-jquery-to-real-window-object-with-webpack

// THIS IS MAKING jQuery AVAILABLE EVEN INSIDE Views FOLDER


require("channels");
require("jquery");
require("jquery-ui");
require("js/jquery-ui.min.js");
require("js/active.js");
require("js/modal-dialog.js");
require("js/modal-dialog-const.js");
require("bootstrap");

require("js/convert.js");
require("js/finalcountdown.min.js");
require("js/slicknav.min.js");
require("js/colors.js");

//require('owl.carousel/dist/assets/owl.carousel.css');
require('owl.carousel');
require("js/magnific-popup.js");
require("js/facnybox.min.js");
require("js/ytplayer.min.js");
require("js/flex-slider.js");
require("js/scrollup.js");
require("js/onepage-nav.min.js");
//require("js/map-script.js");
//require("js/gmap.min.js");
require("js/nicesellect.js");
require("ckeditor4");

//require("niceselect");
// === require("ckeditor")
require("js/loader.js");
//require("js/ckeditor_config.js");
require("js/easing.js");
require("js/cart.js");





