


document.addEventListener("turbolinks:load", function()  {
	
	
	
	
	
	getCart();
	
	var waitingDialog = waitingDialog || (function ($) {
    'use strict';

	// Creating modal dialog's DOM
	var $dialog = $(
		'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
		'<div class="modal-dialog modal-m modal-prem">' +
		'<div class="modal-content">' +
			'<div class="modal-body">' +
				//'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
				'<div class="wait-loader"></div>' +
			'</div>' +
		'</div></div></div>');

	return {
		/**
		 * Opens our dialog
		 * @param message Custom message
		 * @param options Custom options:
		 * 				  options.dialogSize - bootstrap postfix for dialog size, e.g. "sm", "m";
		 * 				  options.progressType - bootstrap postfix for progress bar type, e.g. "success", "warning".
		 */
		show: function (message, options) {
			// Assigning defaults
			if (typeof options === 'undefined') {
				options = {};
			}
			if (typeof message === 'undefined') {
				message = 'Loading';
			}
			var settings = $.extend({
				dialogSize: 'm',
				progressType: '',
				onHide: null // This callback runs after the dialog was hidden
			}, options);

			// Configuring dialog
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			if (settings.progressType) {
				$dialog.find('.progress-bar').addClass('progress-bar-' + settings.progressType);
			}
			$dialog.find('h3').text(message);
			// Adding callbacks
			if (typeof settings.onHide === 'function') {
				$dialog.off('hidden.bs.modal').on('hidden.bs.modal', function (e) {
					settings.onHide.call($dialog);
				});
			}
			// Opening dialog
			$dialog.modal();
		},
		/**
		 * Closes dialog
		 */
		hide: function () {
			$dialog.modal('hide');
		}
	};

	})(jQuery);

	
	
	
	
	function getCart(){
		var ajax_url = "/get_shopping_cart";
		$.ajax({
		       url: ajax_url,
		       //data: params,
		       success: function(data){
		           console.log("added_to_shop", data.cart_items);
		           refreshCart(data);
		           if  ($(".item-in-cart input")[0] &&  $(".item-in-cart input")[0].value > 0){
		           		$("#item-in-cart").html("(" + $(".item-in-cart input")[0].value + ")");
		           		
		           }
		          // $('.dropdown-cart .dropdown-toggle').dropdown("toggle");
		           remove_from_cart();
		       }
		});
		
	}

	$("#clear_cart" ).click(function() {
      	if (confirm('Ви справді бажаєте очистити кошик?')) {
          $.ajax({
			  type: "POST",
			  beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
			  url: "/shopping_cart/clear_cart",
			  success: function(data){
			          //console.log("clear_cart")
			          $("#all_cart").empty();
			          $("#cart_total_count").empty();
					  $("#cart_total_price").empty();
					  $("#send_cart").empty();
		      }
		});
		} else {
    	// Do nothing!
		}
	});

	$("#send_cart" ).click(function() {
       console.log("#send_cart" );
       getCart();
	});

	
	remove_from_cart();
	
	function remove_from_cart(){
	$(".remove-from-cart" ).click(function(e) {
		  $('.preloader').show();
  		  var item_id = $(this).attr('id').split("_")[3];
  		  var big_cart_item = $(this).hasClass("big-cart-item");
          if (confirm('Ви справді бажаєте видалити з кошику?')) {
          $.ajax({
			  type: "POST",
			  beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
			  url: "/shopping_cart/delete_cart_item",
			  data: {"element_id":item_id},
			  success: function(data){
			          console.log("remove-from-cart",  big_cart_item);
			          
			          var item_to_delete = "#cart_line_" + data.removed_id;
			          $(item_to_delete).empty();
			          refreshCart(data);
			          
			          if  ($(".item-in-cart").length > 0){
		           		var item_id = data.removed_id;
		           		var inCartItem = $("[data-item-id='" + item_id + "']");
		           		$(inCartItem).html("(" + 0 + ")");
		           		$(".cart-quantity input").val(0);
		           	  }
		           	  if (big_cart_item == false){
		           	  	if (!$(".dropdown-cart").hasClass("open")){
		           	  		$('.dropdown-cart .dropdown-toggle').dropdown("toggle");
		           	  	}
		           	  	remove_from_cart();
		           	  }
		           	  else{
		           	  	$('.dropdown-cart a.dropdown-toggle').attr("href", "");
		           	  	$('.dropdown-cart .dropdown-toggle').attr("data-toggle", "");
		           	  	$('.dropdown-cart .dropdown-toggle').removeClass("dropdown-toggle");
		           	  }
		           	  $('.preloader').hide();

		      }
		});
		return false;
		} else {
			$('.preloader').hide();
    	// Do nothing!
		}
		
	});
	return false;
	}

	remove_from_cart_big();
	
	function remove_from_cart_big(){
	$(".remove-from-cart-big" ).click(function(e) {
  		  var item_id = $(this).attr('id').split("_")[3];
  		  var big_cart_item = $(this).hasClass("big-cart-item");
          if (confirm('Ви справді бажаєте видалити з кошику?')) {
          $.ajax({
			  type: "POST",
			  beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
			  url: "/shopping_cart/delete_cart_item",
			  data: {"element_id":item_id},
			  success: function(data){
			          console.log("remove-from-cart-big",  big_cart_item);
			          
			          var item_to_delete_mobile = "#cart_line_mobile_" + data.removed_id;
			          $(item_to_delete_mobile).empty();
			          var item_to_delete = "#cart_line_" + data.removed_id;
			          $(item_to_delete).empty();
			          refreshCart(data);
			          
			          if  ($(".item-in-cart").length > 0){
		           		var item_id = data.removed_id;
		           		var inCartItem = $("[data-item-id='" + item_id + "']");
		           		$(inCartItem).html("(" + 0 + ")");
		           		$(".cart-quantity input").val(0);
		           	  }
		           	  if (big_cart_item == false){
		           	  	if (!$(".dropdown-cart").hasClass("open")){
		           	  		$('.dropdown-cart .dropdown-toggle').dropdown("toggle");
		           	  	}
		           	  	remove_from_cart();
		           	  }
		           	  else{
		           	  	$('.dropdown-cart a.dropdown-toggle').attr("href", "");
		           	  	$('.dropdown-cart .dropdown-toggle').attr("data-toggle", "");
		           	  	$('.dropdown-cart .dropdown-toggle').removeClass("dropdown-toggle");
		           	  }

		      }
		});
		return false;
		} else {
			
    	// Do nothing!
		}
		
	});
	return false;
	}



//	$(".editable_cart_item_number" ).editInPlace({
//		default_text:"&nbsp&nbsp&nbsp&nbsp&nbsp",
//		url: "/shopping_cart/edit_item_quantity"
//		//beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))}
//	});

/*
	$(".editable_cart_item_number" ).editInPlace({
		default_text:"&nbsp&nbsp&nbsp&nbsp&nbsp",
		callback: function(idOfEditor, enteredText, orinalHTMLContent, settingsParams, animationCallbacks){
		 $.ajax({
			  type: "POST",
			  data: {"element_id":idOfEditor, "update_value": enteredText},
			  beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
			  url: "/shopping_cart/edit_item_quantity",
			  success: function(data){
		      }
		})

		return(enteredText);
	}
  })
*/
  	$(".add-to-cart").click(function() {
        console.log("vasja", $(this));
        $('.preloader').show();
        if ($('.quantity-container').length > 0) {
        	var quantity_input = $('.quantity-container input')[0].value;
        	var quantity_incart = $('.add_to_shop_button input')[0].value;
        
	        //if (quantity_input != quantity_incart){
	        if (quantity_input == 0 || quantity_input != quantity_incart){
		      	var item_to_cart = $(this).attr('id').split('_');
		      	var item_to_cart_id = item_to_cart[2];
		      	var item_category_id = item_to_cart[3];
		      	$(".cart-quantity input")[0].value = ($(".cart-quantity input")[0].value != 0 ? $(".cart-quantity input")[0].value : 1);
		      	var params = ($(".cart-quantity input")[0] != undefined) ? {quantity: $(".cart-quantity input")[0].value, category_id: item_category_id} : {category_id: item_category_id}; 
		      	var ajax_url = "/shopping_cart/" + item_to_cart_id;
				$.ajax({
				       url: ajax_url,
				       data: params,
				       success: function(data){
				           console.log("added_to_shop", data.cart_items, data);
				           refreshCart(data);
						   $('.add_to_shop_button input')[0].value = $('.quantity-container input')[0].value;
						   /*
				           if  ($(".item-in-cart").length > 0){
				           		var item_id = data.added_to_cart_item.item_id;
				           		var inCartItem = $("[data-item-id='" + item_id + "']");
				           		$(inCartItem).html("(" + data.cart_item_count + ")");
				           }
				           */
				           $('.dropdown-cart .dropdown-toggle').dropdown("toggle");
				           
				           remove_from_cart();
				           $('.preloader').hide();
				       }
				});
			}
			else{
				alert("Ви не змінили кількість");
				$('.preloader').hide();
			}
		}
      });
      
      
      
      
      
      $("#big_cart_update").click(function(){
      	console.log("vasja-up");
      });
      
      $('#checkout-button').click(function(){
      	console.log('#checkout-button-press');
      	console.log($("input#user_email").val());
      	console.log($("input#user_password").val());
      	console.log($("input#user_password_confirmation").val());
      	var ajax_url = "/users.js";
      	var params={user:{password: $("input#user_password").val(), password_confirmation:$("input#user_password_confirmation").val(), email: $("input#user_email").val()}};
      	$.ajax({
		       url: ajax_url,
		       data: params,
		       type: "json",
		       method: "POST",
		       success: function(data){
		           console.log('#checkout-button-success');
		       },
		       fail: function(data){
		          //console.log('#checkout-button-fail');
		       }
		});
      });
      
      
      
      $('input[name="select-type-of-registration"]').change(function(){
      	$(".registration-types").hide();
      	$(".registration-types-next").hide();
		var radio = $('input[name="select-type-of-registration"]:checked').val();
			if (radio == 'new-register'){
				console.log(radio);
				$("#new-registration").show();
				$("#register-new-user").show();
			}
			else if(radio == 'already-registered'){
				$("#already-reg").show();
				$("#already-registered-login").show();
				console.log(radio);
			}
			else{;
				$("#as-guest-reg").show();
				$("#register-as-gest").show();
				console.log(radio);
			}
			
		});
      
      
      function refreshCart(data){
      			   var lang = $("#lang-checker").text();
      			   $("#shopping-cart-count").html(data.cart_items_count);
      			   if (data.cart_items_count > 0){
      			   		$(".mobile-cart-nav").show();
      			   		$(".mobile-cart-nav-inner-img").show();
      			   		$("#shopping-cart-count-mobile").html(data.cart_items_count);
      			   	}
      			   	else{
      			   		$(".mobile-cart-nav").hide();
      			   		$(".mobile-cart-nav-inner-img").hide();
      			   	}
		           
		           if  ($("#item-in-cart").length > 0){
		           		$("#item-in-cart").html("(" + data.cart_item_count + ")");
		           }
		           if ($(".cart-product-name-info").length < 1){
		           		$(".shopping-cart").empty();
		           		if (lang == "ru"){
		           			$(".shopping-cart").html("<h3>Корзина пуста</h3>");
		           		}
		           		else{
		           			$(".shopping-cart").html("<h3>Кошик порожній</h3>");
		           		}
		           }
		           if  ($("#items-in-cart").length > 0){
		           		$("#items-in-cart").empty();
		           		if (data.cart_items.length !=0){
		           			$("#items-in-cart").append("");
		           			$.each(data.cart_items, function( index, item ) {
		           				console.log("item.quantity_in_cart", item.quantity_in_cart);
		           				if (item.quantity_in_cart != 0){
			           				if (item.avatar.main_small.url == null){
			           					item.avatar.main_small.url = "/images/no_photo_new_p_s.png";
			           				} 
			           				if (lang == "ru"){
				           				$("#items-in-cart").append(
				           					'<div class="cart-item product-summary">'+
											'<div class="row">' +
											'<div class="col-4">' +
											'<div class="image">' +
											'<a href="/ru'+
											item.href +
											'" data-turbolinks="false">' +
											'<img style="width:61px; height:47px;" src="' +
											item.avatar.main_small.url +
											'" alt=""></a>'+
											'</div>' +
											'<div class="pull-right">'+ 
											item .quantity_in_cart + 
											' шт.</div>' +
											'</div>' +
											'<div class="col-6">' +
											'<h3 class="name"><a href="/ru'+
											item.href +
											'" data-turbolinks="false">' +
											item.name_ru +
											'</a></h3>' +
											'<div class="price">'+ 
											item.price_real + 
											' грн.</div>' +
											'</div>' +
											'<div class="col-2 action">' +
											'<div class="remove-from-cart" id="remove_item_id_' + item.id +'">' +
											'<i class="fa fa-trash"></i></div>' +
											'</div>' +
											'</div>' +
											'</div>' +
											'<div class="clearfix"></div>' +
											'<hr>');	
			           				}
			           				else{
			           				$("#items-in-cart").append(
			           					'<div class="cart-item product-summary">'+
										'<div class="row">' +
										'<div class="col-4">' +
										'<div class="image">' +
										'<a href="'+
										item.href +
										'" data-turbolinks="false">' +
										'<img style="width:61px; height:47px;" src="' +
										item.avatar.main_small.url +
										'" alt=""></a>'+
										'</div>' +
										'<div class="pull-right">'+ 
										item .quantity_in_cart + 
										' шт.</div>' +
										'</div>' +
										'<div class="col-6">' +
										'<h3 class="name"><a href="'+
										item.href +
										'" data-turbolinks="false">' +
										item.name_ua +
										'</a></h3>' +
										'<div class="price">'+ 
										item.price_real + 
										' грн.</div>' +
										'</div>' +
										'<div class="col-2 action">' +
										'<div class="remove-from-cart" id="remove_item_id_' + item.id +'">' +
										'<i class="fa fa-trash"></i></div>' +
										'</div>' +
										'</div>' +
										'</div>' +
										'<div class="clearfix"></div>' +
										'<hr>');
									}
								}
							});
							if (lang == "ru"){
								$("#items-in-cart").append(
								'<div class="clearfix cart-total">' +
								'<div class="pull-right">' +
								'<span class="text">Всего :</span><span class="price">' +
								+ data.cart_items_sum/100 +
								' грн.</span>' +
								'</div>' +
								'<div class="clearfix"></div><hr>' +
								'<a href="/ru/shopping_cart" class="btn btn-upper btn-primary btn-block m-t-20 upper-next-cart-button"  data-turbolinks="false">Дальше >></a>' +
								'</div></li>');
								
							}
							else{
								$("#items-in-cart").append(
								'<div class="clearfix cart-total">' +
								'<div class="pull-right">' +
								'<span class="text">Разом :</span><span class="price">' +
								+ data.cart_items_sum/100 +
								' грн.</span>' +
								'</div>' +
								'<div class="clearfix"></div><hr>' +
								'<a href="/shopping_cart" class="btn btn-primary make-order btn-block m-t-20 upper-next-cart-button"  data-turbolinks="false">Оформити замовлення>></a>' +
								'</div>'+
								'<div><a class="continue-shopping" data-turbolinks="false">Продовжити покупки>></a></div>'+
								'</li>');
							}
		           		}
		           }
		           if  ($("#shopping-cart-sum").length > 0){
		           		$("#shopping-cart-sum").empty();
		           		$("#shopping-cart-sum").html(data.cart_items_sum/100);
		           }
		           if  ($("#shopping-big-cart-sum").length > 0){
		           		$("#shopping-big-cart-sum").empty();
		           		$("#shopping-big-cart-sum").html(data.cart_items_sum/100 + " грн");
		           }
		     	  waitingDialog.hide();
      }
      
	  $( "form#form-registered" ).submit(function( event ) {
	  	$( "span#no-email-registered" ).empty();
	  	$( "span#no-password-registered" ).empty();
	  	console.log("!!!");
  		//if ( $("input#user_email_").val() == "" ) {
  	    if ( $("input#user_email_").val() == "" ||  !validateEmail($("input#user_email_").val())) {
    		if ( $("input#user_email_").val() != "" &&  !validateEmail($("input#user_email_").val())) {
    			$( "span#no-email-registered" ).text( "   Невірний формат email" ).show();
    		}
    		else{
    			$( "span#no-email-registered" ).text( "   Поле не може бути порожнім!" ).show();
    		}
    		waitingDialog.hide();
    		event.preventDefault();
  		}
  		if ( $("input#user_password_").val() == "" ) {
    	  	$( "span#no-password-registered" ).text( "   Поле не може бути порожнім!" ).show();//.fadeOut( 3000 );
    	  	waitingDialog.hide();
  			event.preventDefault();
  		}
  		
  		return;
	 });
	 
	  $( "form#form-new-registration" ).submit(function( event ) {
	  	console.log("?");
	  	$( "span#no-email-new-registration" ).empty();
	  	$( "span#no-password-new-registration" ).empty();
	  	$( "span#no-password-confirmation-new-registration" ).empty();
	  	$( "span#no-phone-new-registration" ).empty();
	  	$( "span#no-name-new-registration" ).empty();
	  	$( "span#no-city-new-registration" ).empty();
	  	
  		if ( $("input#user_email").val() == "" ||  !validateEmail($("input#user_email").val())) {
  			if ( $("input#user_email").val() != "" &&  !validateEmail($("input#user_email").val())) {
  				$( "span#no-email-new-registration" ).text( "  Невірний формат email" );//.fadeOut( 3000 );
  			}
  			else{
  				$( "span#no-email-new-registration" ).text("   Поле не може бути порожнім!");//.fadeOut( 3000 );
  			}
    			
    		waitingDialog.hide();
    		event.preventDefault();
  		}
  		
  		if ( $("input#user_phone_reg").val() == "" ) {
    		$( "span#no-phone-new-registration" ).text("   Поле не може бути порожнім!");//.fadeOut( 3000 );
    		waitingDialog.hide();
    		event.preventDefault();
  		}
		
		if ( $("input#user_name_reg").val() == "" ) {
    		$( "span#no-name-new-registration" ).text("   Поле не може бути порожнім!");//.fadeOut( 3000 );
    		waitingDialog.hide();
    		event.preventDefault();
  		}
  		
  		if ( $("input#user_city_reg").val() == "" ) {
    		$( "span#no-city-new-registration" ).text("   Поле не може бути порожнім!");//.fadeOut( 3000 );
    		waitingDialog.hide();
    		event.preventDefault();
  		}
  		
  		if ( $("input#user_password").val() == "" ) {
    	  	$( "span#no-password-new-registration" ).text("   Поле не може бути порожнім!");//.fadeOut( 3000 );
    	  	waitingDialog.hide();
    	  	event.preventDefault();
  		}

  	    if ( $("input#user_password_confirmation").val() == "" ) {
    	  	$( "span#no-password-confirmation-new-registration" ).text("   Поле не може бути порожнім!");//.fadeOut( 3000 );
    	  	waitingDialog.hide();
    	  	event.preventDefault();
  		}
  		
  		if (($("input#user_password").val() != "") && ($("input#user_password_confirmation").val() != "") &&  ($("input#user_password").val() != $("input#user_password_confirmation").val())) {
    	  	$("#no-password-new-registration" ).text("   Поля не співпадають!" );//.fadeOut( 3000 );
    	  	$( "span#no-password-confirmation-new-registration" ).text( "   Поля не співпадають!" );//show().fadeOut( 3000 );
    	  	waitingDialog.hide();
    	  	event.preventDefault();
  		}
  		else if ( $("input#user_password").val() != "" && $("input#user_password").val().length < 8 && $("input#user_password").val() == $("input#user_password_confirmation").val()) {
    	  	$( "#no-password-new-registration" ).text( "   Введіть не менше 8 символів!" );//.fadeOut( 3000 );
    	  	$( "span#no-password-confirmation-new-registration" ).text( "   Введіть не менше 8 символів!" );//.show();//.fadeOut( 3000 );
    	  	waitingDialog.hide();
    	  	event.preventDefault();
  		}
  		return;
	 });
	 
	
	
	$("#shipping-select").change(function() {
		var id = $(this).find(":selected").val();
		$("#shipping-select-out").append("<div id='paying-select-out-loading' style='padding:10px'><img src='/images/loading.gif'></div>");
		if (typeof id !== 'undefined'){
			var dataShipping = id;
			var dataLang = $("#lang-checker").text();
			$.ajax({
				url: '/shipping_select.json',
				dataType: "json",
				data: {shipping: dataShipping, locale_: dataLang},
				cache: false,
				success: function(response) {
					//console.log(response.data.shipping, response.data.locale);
					if (response.data.locale == "ru"){
						if(response.data.shipping == "1") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Форма оплаты</label><select class='form-control' name='user[paying]'  id='paying-select'>" +
	    							"<option value='0'><span class='select-label'>Выберите форму оплаты</span></option>" +
	    							"<option value='1'>Наложеный платеж</option>" +
	    							"<!--<option value='2'>Оплатить картой VISA / MASTERCARD</option>-->" +
	    							"<option value='3'>Получить счет фактуру</option>" +
	  							"</select></div>");
	  						payingSelect(1);
						}
						else if(response.data.shipping == "2") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Форма оплати</label><select class='form-control' name='user[paying]' id='paying-select'>" +
	    							"<option value='0'><span class='select-label'>Выберите форму оплаты</span></option>" +
	    							"<option value='1'>Наложеный платеж</option>" +
	    							"<!--<option value='2'>Оплатить картой VISA / MASTERCARD</option>-->" +
	    							"<option value='3'>Получить счет фактуру</option>" +
	  							"</select></div>");
	  						payingSelect(2);
						}
						else if(response.data.shipping == "3") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Форма оплати</label><select class='form-control' name='user[paying]' id='paying-select'>" +
	    							"<option value='0'><span class='select-label'>Выберите форму оплаты</span></option>" +
	    							"<option value='1'>Оплата наличными в магазине</option>" +
	    							"<!--<option value='2'>Оплатить картой VISA / MASTERCARD</option>-->" +
	    							"<option value='3'>Получить счет фактуру</option>" +
	  							"</select></div>");
	  						payingSelect(3);
						}
						else if(response.data.shipping == "4") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Способ доставки и оплаты</label>"+
								"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input  textarea-delivery textatea-add' placeholder='Укажите способ доставки и форму оплаты'></textarea>"+
	  							"</div>");
	  						UserDeliveryFocus();
	  						$('#delivery_hidden').empty();
	  						$("#paying-select-out-loading").remove();
	  						//payingSelect(4);
						}	
						else {
							$("#paying-select-out-loading").remove();
							$('#paying-select-out').remove();
						}
					}
					else{
						if(response.data.shipping == "1") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Форма оплати</label><select class='form-control' name='user[paying]'  id='paying-select'>" +
	    							"<option value='0'><span class='select-label'>Виберіть форму оплати</span></option>" +
	    							"<option value='1'>Накладений платіж</option>" +
	    							"<!--<option value='2'>Оплатити карткою VISA / MASTERCARD</option>-->" +
	    							"<option value='3'>Отримати рахунок фактуру</option>" +
	  							"</select></div>");
	  						payingSelect(1);
						}
						else if(response.data.shipping == "2") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Форма оплати</label><select class='form-control' name='user[paying]' id='paying-select'>" +
	    							"<option value='0'><span class='select-label'>Виберіть форму оплати</span></option>" +
	    							"<option value='1'>Накладений платіж</option>" +
	    							"<!--<option value='2'>Оплатити карткою VISA / MASTERCARD</option>-->" +
	    							"<option value='3'>Отримати рахунок фактуру</option>" +
	  							"</select></div>");
	  						payingSelect(2);
						}
						else if(response.data.shipping == "3") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Форма оплати</label><select class='form-control' name='user[paying]' id='paying-select'>" +
	    							"<option value='0'><span class='select-label'>Виберіть форму оплати</span></option>" +
	    							"<option value='1'>Оплата готівкою в магазині</option>" +
	    							"<!--<option value='2'>Оплатити карткою VISA / MASTERCARD</option>-->" +
	    							"<option value='3'>Отримати рахунок фактуру</option>" +
	  							"</select></div>");
	  						payingSelect(3);
						}
						else if(response.data.shipping == "4") {
							$('#paying-select-out').remove();
							$("#shipping-select-out").append("<div id='paying-select-out'><label class='info-title info-title-red-1'>Спосіб доставки і оплати</label>"+
								"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input  textarea-delivery textatea-add' placeholder='Вкажіть спосіб доставки і форму оплати'></textarea>"+
	  							"</div>");
	  						UserDeliveryFocus();
	  						$('#delivery_hidden').empty();
	  						$("#paying-select-out-loading").remove();
	  						//payingSelect(4);
						}	
						else {
							$("#paying-select-out-loading").remove();
							$('#paying-select-out').remove();
						}
					}
				}
			});
		}
		
		
	});
	
	
	function payingSelect(shipping){
			(function(){
					$("#paying-select-out-loading").remove();
				    $('.info-title-red').css({'color':'#555555'});
					$("#paying-select").change(function() {
						var id = $(this).find(":selected").val();
						$("#shipping-select-out").append("<div id='paying-select-out-loading' style='padding:10px'><img src='/images/loading.gif'></div>");
						if (id != 0){
							$('#paying_hidden').empty();
							var dataLang = $("#lang-checker").text();
							var dataString = id;
							$.ajax({
								url: '/paying_select.json',
								dataType: "json",
								data: {paying: dataString, locale_: dataLang},
								cache: false,
								success: function(response) {
									//console.log(response.data.paying, response.data.locale);
									if (response.data.locale == "ru"){
										if(shipping == "1") {
												$('#address-select-out').remove();
												$("#paying-select-out").append("<div id='address-select-out'><label class='info-title info-title-red-2'>Адрес доставки</label>"+
												"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input textarea-delivery textatea-add' placeholder='Укажите адрес доставки (или № отделения) и любую дополнительную информацию'></textarea>"+ 
					  							"</select></div>");
					  							UserDeliveryFocus();
					  							$('#delivery_hidden').empty();	
										}
										else if(shipping == "2") {
											//console.log("shipping-2", response.data.paying);
												$('#address-select-out').remove();
												$("#paying-select-out").append("<div id='address-select-out'><label class='info-title info-title-red-2'>Адрес доставки</label>"+
												"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input  textarea-delivery textatea-add'  placeholder='Укажите адрес доставки (или № отделения) и любую дополнительную информацию'></textarea>"+ 
					  							"</select></div>");
					  							UserDeliveryFocus();
					  							$('#delivery_hidden').empty();
										}
										else if(shipping == "3") {
											//console.log("shipping-3", response.data.paying);
												$('#address-select-out').remove();
												$("#paying-select-out").append("<div id='address-select-out'><label class='info-title info-title-red-2'>Дополнительная информация</label>"+
												"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input  textarea-delivery textatea-add' placeholder='Дополнительная информация'></textarea>"+ 
					  							"</select></div>");
					  							UserDeliveryFocus();
					  							$('#delivery_hidden').empty();
										}
										else if(shipping == "4") {
											$("#paying-select-out-loading").remove();
											//console.log("shipping-4", response.data.paying);
										}	
										else {
											$("#paying-select-out-loading").remove();
											//console.log("shipping-else");
											//console.log("shipping-else", response.data.paying);
											
											/*
											$('#paying-select-out').remove();
											*/
										}
									}
									else{
										if(shipping == "1") {
												$('#address-select-out').remove();
												$("#paying-select-out").append("<div id='address-select-out'><label class='info-title info-title-red-2'>Адреса доставки</label>"+
												"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input textarea-delivery textatea-add' placeholder='Вкажіть адресу доставки (або № відділення) та будь-яку додаткову інформацію'></textarea>"+ 
					  							"</select></div>");
					  							UserDeliveryFocus();
					  							$('#delivery_hidden').empty();
										}
										else if(shipping == "2") {
											//console.log("shipping-2", response.data.paying);
												$('#address-select-out').remove();
												$("#paying-select-out").append("<div id='address-select-out'><label class='info-title info-title-red-2'>Адреса доставки</label>"+
												"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input  textarea-delivery textatea-add'  placeholder='Вкажіть адресу доставки (або № відділення) та будь-яку додаткову інформацію'></textarea>"+ 
					  							"</select></div>");
					  							UserDeliveryFocus();
					  							$('#delivery_hidden').empty();
										}
										else if(shipping == "3") {
											//console.log("shipping-3", response.data.paying);
												$('#address-select-out').remove();
												$("#paying-select-out").append("<div id='address-select-out'><label class='info-title info-title-red-2'>Додаткова інформація</label>"+
												"<textarea name='user[delivery]' class='form-control unicase-form-control textarea-input  textarea-delivery textatea-add' placeholder='Додаткова інформація'></textarea>"+ 
					  							"</select></div>");
					  							UserDeliveryFocus();
					  							$('#delivery_hidden').empty();
										}
										else if(shipping == "4") {
											$("#paying-select-out-loading").remove();
											//console.log("shipping-4", response.data.paying);
										}	
										else {
											$("#paying-select-out-loading").remove();
											//console.log("shipping-else", response.data.paying);
											//console.log("shipping-else");
											/*
											$('#paying-select-out').remove();
											*/
										}
									}
								}
							});
						}
					});
		})();
	}
	
	
	(function(){
		if ($(".hide-shipping-select")[0]){
			$("#shipping-select-out").detach().appendTo('#detach-div');
			
			/*
			$("#shipping-select-out").append('<select class="form-control" id="shipping-select"  name="user[shipping]">' +
	    							'<option value="0" >Виберіть варіант доставки:</option>' +
	    							'<option value="1">Нова Пошта</option>' +
	    							'<option value="2">Автолюкс</option>' +
	    							'<option value="3">Самовивіз</option>' +
	    							'<option value="4">Інше</option>' +
	    							'<span id="delivery_hidden"><input type="hidden" name="user[delivery]" value=""></span>' +
	    							'<span id="paying_hidden"><input type="hidden" name="user[paying]" value=""></span>' +
	  							'</select>');
	  		*/
		}
		else{
			//$("#shipping-select-out").empty();
		}
		if ($(".hide-shipping-select-ru")[0]){
			$("#shipping-select-out").detach().appendTo('#detach-div');
			/*
			$("#shipping-select-out").append('<select class="form-control" id="shipping-select"  name="user[shipping]">' +
	    							'<option value="0" >Выберите вариант доставки:</option>' +
	    							'<option value="1">Новая Почта</option>' +
	    							'<option value="2">Автолюкс</option>' +
	    							'<option value="3">Самовывоз</option>' +
	    							'<option value="4">Другое</option>' +
	    							'<span id="delivery_hidden"><input type="hidden" name="user[delivery]" value=""></span>' +
									'<span id="paying_hidden"><input type="hidden" name="user[paying]" value=""></span>' +
	  							'</select>');
	  		*/
		}
		else{
			//
		}
		
		
		
	})();
	
	$("#span-to-add-shipping-select").click(function(){
		$("#span-to-add-shipping-select").empty();
		$("#span-shipping-select-response").empty();
		$("#shipping-select-out").detach().appendTo('#hide-shipping-select');
		/*
		$("#shipping-select-out").append('<select class="form-control" id="shipping-select"  name="user[shipping]">' +
	    							'<option value="0" >Виберіть варіант доставки:</option>' +
	    							'<option value="1">Нова Пошта</option>' +
	    							'<option value="2">Автолюкс</option>' +
	    							'<option value="3">Самовивіз</option>' +
	    							'<option value="4">Інше</option>' +
	    							'<span id="delivery_hidden"><input type="hidden" name="user[delivery]" value=""></span>' +
	    							'<span id="paying_hidden"><input type="hidden" name="user[paying]" value=""></span>' +
	  							'</select>');
	  	*/
		$("#hide-shipping-select").attr('style', 'display: block !important');
	});
	
	$("#span-to-add-shipping-select-ru").click(function(){
		$("#span-to-add-shipping-select-ru").empty();
		$("#span-shipping-select-response-ru").empty();
		$("#shipping-select-out").detach().appendTo('#hide-shipping-select-ru');
		/*
		$("#shipping-select-out").append('<select class="form-control" id="shipping-select"  name="user[shipping]">' +
	    							'<option value="0" >Выберите вариант доставки:</option>' +
	    							'<option value="1">Новая Почта</option>' +
	    							'<option value="2">Автолюкс</option>' +
	    							'<option value="3">Самовывоз</option>' +
	    							'<option value="4">Другое</option>' +
	    							'<span id="delivery_hidden"><input type="hidden" name="user[delivery]" value=""></span>' +
									'<span id="paying_hidden"><input type="hidden" name="user[paying]" value=""></span>' +
	  							'</select>');
	  	*/
		$("#hide-shipping-select-ru").attr('style', 'display: block !important');
	});
});
